$(document).ready(function () {
	// Main navigation
	// Opens menu

	/*$(".navbar-collapse").toggleClass("show");*/
	$(".navbar-collapse").collapse("hide");
	

	$('[data-toggle="slide-collapse"]').on('click', function () {		
		$(".navbar-toggler").toggleClass("expanded");
		$(".navbar-nav").toggleClass("active");
		$(".navbar-collapse").toggleClass("active").show();
		$(".dropdown-menu").toggleClass("active").show();
		$(".dropdown-menu").toggleClass("show");
	});

	// Close 2nd level dropdown on click
	$('li.nav-item').on('click', function () {
		$("dropdown-menu").this.toggleClass("show");
	});

	// Closes all menus on click
	$('.dropdown-item').on('click', function () {	
		$(".navbar-toggler").toggleClass("expanded");
		$(".navbar-nav").toggleClass("active");
		$(".navbar-collapse").toggleClass("active");
	});
	// END of Main navigation

	// Gallery lightbox invoke
	$('.showcase').lightcase();

	$('.collapse').collapse();

	// For navigation to make parent element clickable
	jQuery(function ($) {
		$('.navbar .dropdown').hover(function () {
			$(this).find('.dropdown-menu').first().stop(true, true).delay(250).slideDown();

		}, function () {
			$(this).find('.dropdown-menu').first().stop(true, true).delay(100).slideUp();

		});

		$('.navbar .dropdown > a').click(function () {
			location.href = this.href;
		});

	});
	// End of navigation fix

	// Cover Level hide/show
	var deviceWidth = $(window).width();

	$("[id^='label-']").click(function () {
		
		if (deviceWidth > 1024) {
			var coverId = $(this).attr("coverId");

			if ($(this).hasClass("show")) {
				$(this).toggleClass("show");
				$(this).toggleClass("collapsed");
			} else {
				$(".show").toggleClass("collapsed");
				$(".collapsed").removeClass("show");
				$(this).toggleClass("collapsed");
				$(this).addClass("show");

				//SCWIM-875:Anchor links - Purely Pets and Petwise Mobile Website
				//$('html, body').animate({ scrollTop: ($(this).offset().top + $(this).height() / 2) - 150 }, 500);
			}

			$(".expandible-desktop").each(function () {
				if ($(this).attr("coverId") == coverId) {
					$(this).toggleClass("collapsed");


					//SCWIM-875:Anchor links - Purely Pets and Petwise Mobile Website
					var elOffset = $(this).offset().top;
					var elHeight = $(this).height();
					var windowHeight = $(window).height();
					var offset;
					if (elHeight < windowHeight) {
						offset = elOffset - ((windowHeight / 2) - (elHeight / 2));
					}
					else {
						offset = elOffset;
					}
					setTimeout(function () {
						$(this).scrollTop(offset);
					}, 500);

				} else {
					$(this).addClass("collapsed");
				}
			});
		}
		if (deviceWidth < 1025) {
			var mobileId = $(this).attr("coverId");

			if ($(this).hasClass("show")) {
				$(this).toggleClass("show");
				$(this).toggleClass("collapsed");
			} else {
				$(".show").toggleClass("collapsed");
				$(".collapsed").removeClass("show");
				$(this).toggleClass("collapsed");
				$(this).addClass("show");

				
			
			}

			$(".expandible-mobile").each(function () {
				if ($(this).attr("mobileId") == mobileId) {
					$(this).toggleClass("collapsed-mobile");

					//SCWIM-875:Anchor links - Purely Pets and Petwise Mobile Website
					var elOffset = $(this).offset().top;
					var elHeight = $(this).height();
					var windowHeight = $(window).height();
					var offset;
					if (elHeight < windowHeight) {
						offset = elOffset - ((windowHeight / 2) - (elHeight / 2));
					}
					else {
						offset = elOffset;
					}
					setTimeout(function () {
						$(this).scrollTop(offset);
					}, 500);

				} else {
					$(this).addClass("collapsed-mobile");
				}
			});
		}
	});

	//End of Cover Level hide/show

	// Cover levels accordion
	$('.accordion-expand').click(function () {
		if ($(this).hasClass('active')) {
			$(this).children(".show-hide").text('Click to show less');
		} else {
			$(this).children(".show-hide").text('Click to show more');
		}
	});
	// End of Cover levels accordion
});